<template>
  <v-content>
    <v-container class="back-login" fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 lg3 xl2>
          <v-card>
            <v-card-text class="px-4 pb-0">
              <div class="text-center mb-4">
                <img style="width: 200px;margin: 0 auto;display: block;" :src="'/img/rosalinda-red.png'" />
              </div>
              <v-form @submit="loginAuth">
                <v-text-field
                  v-model="usuario.nick"
                  browser-autocomplete="off"
                  single-line
                  outline
                  name="login"
                  label="Usuario"
                  type="text"
                  autofocus
                  @keyup.enter="loginAuth"
                ></v-text-field>
                <v-text-field
                  v-model="usuario.pass"
                  browser-autocomplete="off"
                  single-line
                  outline
                  name="current-password"
                  label="Contraseña"
                  type="password"
                  @keyup.enter="loginAuth"
                >
                </v-text-field>
                <v-checkbox v-model="rememberme" color="primary" class="ma-0 pa-0" label="Recordarme"> </v-checkbox>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
              <v-btn :loading="loading" :disabled="loading" color="accent" block large @click="loginAuth">
                Ingresar
              </v-btn>
            </v-card-actions>
            <v-divider light></v-divider>
            <v-footer class="pa-3">
              <v-spacer></v-spacer>
              <div>Version {{ version }} - &copy; {{ new Date().getFullYear() }}</div>
            </v-footer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
/* eslint-disable func-names */
import { detect } from 'detect-browser'
import { LOGIN } from '../config'
import { auth, firestore } from '../fireStore'

export default {
  name: 'Login',
  data() {
    return {
      localIP: 0,
      publicIP: 0,
      loading: false,
      usuario: {
        nick: '',
        pass: ''
      },
      rememberme: false
    }
  },
  computed: {
    version() {
      return this.$store.getters.getVersion
    },
    settings() {
      return this.$store.getters.getSettings
    },
    getRememberme() {
      return this.$store.getters.getRememberme
    }
  },
  mounted() {
    this.getLocalIP()
    this.getPublicIP()
    if (this.$store.getters.isLoggedIn) {
      this.$router.push({ name: this.settings.page })
    }
    const rememberme = this.getRememberme
    if (rememberme.nick !== undefined) {
      this.usuario.nick = rememberme.nick
      this.usuario.pass = rememberme.pass
      this.rememberme = true
    }
  },
  methods: {
    getPublicIP() {
      this.$http('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=text')
        .then(res => {
          this.publicIP = res.data
        })
        .catch(error => {
          this.publicIP = 0
        })
    },
    getUserIP(onNewIP) {
      // eslint-disable-next-line max-len
      const myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
      // eslint-disable-next-line new-cap
      const pc = new myPeerConnection({
        iceServers: []
      })
      const noop = function() {}
      const localIPs = {}
      const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g

      function iterateIP(ip) {
        if (!localIPs[ip]) onNewIP(ip)
        localIPs[ip] = true
      }
      // create a bogus data channel
      pc.createDataChannel('')

      // create offer and set local description
      pc.createOffer(sdp => {
        sdp.sdp.split('\n').forEach(line => {
          if (line.indexOf('candidate') < 0) return
          line.match(ipRegex).forEach(iterateIP)
        })

        pc.setLocalDescription(sdp, noop, noop)
      }, noop)

      // listen for candidate events
      pc.onicecandidate = function(ice) {
        // eslint-disable-next-line max-len
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
      }
    },
    getLocalIP() {
      const self = this
      this.getUserIP(ip => {
        self.localIP = ip
      })
    },
    async loginAuth() {
      this.loading = true
      const res = await this.$http({
        url: LOGIN,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: this.$qs.stringify(this.usuario)
      })
      const r = res.data
      console.log(r, 'login')
      if (!r.error) {
        this.login(r)
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: r.msj })
        this.loading = false
      }
    },
    async login(a) {
      const browser = detect()
      const login = a
      await auth.signInWithCustomToken(login.jwt)
      this.setRememberme()
      login.user.token = login.jwt
      this.$store.dispatch('saveUser', login.user)
      console.log(login.user.id)
      firestore
        .collection('usersActives')
        .doc(`user-${login.user.id}`)
        .set({
          name: login.user.name,
          write: false,
          phone: login.number ? login.number : 0,
          id: login.user.id,
          active: true,
          publicIP: this.publicIP,
          localIP: this.localIP,
          browser: {
            name: browser.name,
            version: browser.version,
            OS: browser.os
          }
        })
      firestore
        .collection('usersActives')
        .doc(`user-${login.user.id}`)
        .collection('logins')
        .add({
          publicIP: this.publicIP,
          localIP: this.localIP,
          date: this.$moment.now(),
          browser: {
            name: browser.name,
            version: browser.version,
            OS: browser.os
          }
        })
      this.$store.dispatch('changeSnack', {
        active: true,
        text: `Bienvenido ${login.user.name}`
      })
      if (login.user.role.id === 6) {
        this.$router.push({ name: 'inicioAsociado' })
      } else if (login.user.role.id === 7) {
        this.$router.push({ name: 'taller' })
      } else {
        this.$router.push({ name: this.settings.page })
      }
    },
    setRememberme() {
      if (this.rememberme) {
        this.$store.dispatch('setRememberme', this.usuario)
      }
    }
  }
}
</script>

<style>
.back-login {
  background: -webkit-linear-gradient(205deg, #18f0b8, #e8eaeb, #d3700b);
  background: linear-gradient(245deg, #18f0b8, #e8eaeb, #d3700b);
  background-size: 600% 600%;
  -webkit-animation: Gradient 16s ease infinite;
  animation: Gradient 16s ease infinite;
}
@keyframes Gradient {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
</style>
